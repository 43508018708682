import { useEffect, useRef, useState } from 'react';

import {
  ControlledGamePackFilters,
  GameLikeFilterRemoveButtons,
} from '../../../../app/components/GamePack/GamePackFilters';
import { useAppAnalytics } from '../../../analytics/app/identifiable';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { type GameLikeType } from '../../../types/game';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { useGameLikeFilterOptions } from './Context';
import {
  type Filter,
  type FilterOption,
  type GameLikeFilterOptions,
} from './types';

export function GamePackFilters(props: {
  buttonClassName?: string;
}): JSX.Element {
  const analytics = useAppAnalytics();
  const [filterOptions, updateFilterOptions] =
    useGameLikeFilterOptions('gamePack');

  const handleApply = (options: Partial<GameLikeFilterOptions> | null) => {
    updateFilterOptions(options);
    if (!options) return;
    const analyticsView = Object.fromEntries(
      Object.entries(options).map(([key, value]) => {
        return [key, value.map((v) => v.key)];
      })
    );

    analytics.trackLibraryFiltered(analyticsView);
  };

  return (
    <ControlledGamePackFilters
      buttonClassName={props.buttonClassName}
      filterOptions={filterOptions}
      updateFilterOptions={handleApply}
      analytics={analytics}
      playHistory
    />
  );
}

export function GameLikeFilterDropdown<T>(props: {
  type: GameLikeType;
  filter: Filter<T>;
  getSelectedOptions: (
    filterOptions: Partial<GameLikeFilterOptions>
  ) => FilterOption<T>[] | undefined;
}): JSX.Element | null {
  const { filter, getSelectedOptions } = props;

  const [active, setActive] = useState(false);
  const [checkedState, setCheckedState] = useState<(FilterOption<T> | null)[]>(
    new Array(filter.options.length).fill(null)
  );
  const [filterOptions, updateFilterOptions] = useGameLikeFilterOptions(
    props.type
  );
  const selectedOptions = getSelectedOptions(filterOptions);

  useEffect(() => {
    if (!selectedOptions) return;
    const a = filter.options.map(
      (o) => selectedOptions.find((so) => so.key === o.key) || null
    );
    setCheckedState((prev) => {
      return prev.map((_, i) => {
        return a[i];
      });
    });
  }, [filter.options, selectedOptions]);

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        return item ? null : filter.options[index];
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
  };

  const handleApply = () => {
    updateFilterOptions({
      [filter.name]: checkedState.filter((s) => s !== null),
    });
    setActive(false);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setActive(false);
  });

  return (
    <div className='relative mx-2 first:mx-0 text-white' ref={ref}>
      <button
        type='button'
        className='btn-secondary text-white flex items-center justify-center h-10 w-33'
        onClick={() => setActive(!active)}
      >
        <p className='mr-2'>{filter.displayName || filter.name}</p>
        {active ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {active && (
        <div
          className='flex flex-col border border-secondary rounded-xl 
        px-4 py-3 mt-px absolute bg-black whitespace-nowrap z-5'
        >
          {filter.options.map((f, i) => (
            <label key={i} className='flex flex-row items-center py-1.5'>
              <input
                type='checkbox'
                className='checkbox-dark'
                checked={!!checkedState[i]}
                onChange={() => handleOnChange(i)}
              />
              <p className='ml-2 text-sms'>{f.label}</p>
            </label>
          ))}
          <button
            type='button'
            className='btn-primary h-10 rounded-xl mt-3'
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
}

export function GameLikeFilters(props: {
  type: GameLikeType;
}): JSX.Element | null {
  const [filterOptions, updateFilterOptions] = useGameLikeFilterOptions(
    props.type
  );
  const numOfOptions = Object.values(filterOptions).reduce(
    (cnt, v) => cnt + v.length,
    0
  );

  if (numOfOptions <= 0) return null;

  return (
    <GameLikeFilterRemoveButtons
      filterOptions={filterOptions}
      updateFilterOptions={updateFilterOptions}
    />
  );
}
