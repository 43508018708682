import { plural } from 'pluralize';
import { type ReactNode, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';

import {
  GameLikeLabelMap,
  GameLikeQueryType,
  type GameLikeType,
} from '../../../types/game';
import { type BreadcrumbChain, Breadcrumbs } from '../../Breadcrumbs';
import {
  GameLikeFilterDropdown,
  GameLikeFilters,
  GamePackFilters,
} from './Filters';
import { FilterPresets } from './types';
import { ErrorMessage } from './Utilities';
export { useGameLikeListLoader } from '../../../../app/components/hooks/useGameLikeListLoader';

export function GameLikeListWrapper(props: {
  type: GameLikeType;
  queryType: GameLikeQueryType;
  breadcrumbs: BreadcrumbChain;
  breadcrumb?: string;
  filter?: { enabled?: boolean; visible?: boolean };
  showEmptyMessage: boolean;
  showErrorMessage: boolean;
  canLoad: boolean;
  handleRetry: () => void;
  handleLoad: () => void;
  pinnedManager?: JSX.Element;
  containerClassName?: string;
  children: ReactNode;
}): JSX.Element {
  const { type, breadcrumb, breadcrumbs } = props;

  useEffect(() => {
    if (!breadcrumb) return;
    breadcrumbs.add(type, <div className='text-xl'>{breadcrumb}</div>);
    return () => {
      breadcrumbs.remove(type);
    };
  }, [type, breadcrumb, breadcrumbs]);

  return (
    <div
      className={`w-full h-full min-h-100 bg-black pt-2.5 scrollbar px-10 ${
        props.containerClassName ?? ''
      }`}
    >
      <div className='flex mt-8 mb-2 items-center justify-between'>
        <Breadcrumbs chain={breadcrumbs} />
        {props.filter?.enabled &&
          (type === 'gamePack' ? (
            <GamePackFilters />
          ) : (
            <GameLikeFilterDropdown
              type={type}
              filter={FilterPresets.duration}
              getSelectedOptions={(filterOptions) => filterOptions.duration}
            />
          ))}
        {props.pinnedManager}
      </div>
      {props.filter?.enabled && props.filter?.visible && (
        <GameLikeFilters type={type} />
      )}
      {props.children}
      {props.showErrorMessage && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <ErrorMessage
            text='Something went wrong'
            handleRetry={props.handleRetry}
          />
        </div>
      )}
      {props.showEmptyMessage && (
        <div className='w-full pt-60 flex justify-center text-white text-xl'>
          {props.queryType === GameLikeQueryType.My && (
            <ErrorMessage
              text={`Create your first ${GameLikeLabelMap[type]}`}
            />
          )}
          {props.queryType === GameLikeQueryType.ByTags && (
            <ErrorMessage
              text={`No ${plural(
                GameLikeLabelMap[type]
              )} found, please try again`}
              handleRetry={props.handleRetry}
            />
          )}
          {props.queryType === GameLikeQueryType.Search && (
            <ErrorMessage
              text={`No matching ${plural(GameLikeLabelMap[type])} found`}
            />
          )}
        </div>
      )}
      {props.canLoad && (
        <Waypoint onEnter={props.handleLoad} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}
    </div>
  );
}
